
@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.Popover-wrapper {
  // position: relative;
  height: 48px;
  display: flex;
  align-items: center;
}

.go2197912528 {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.go2197912528 > div,
.Popover {
  top: 8px;
  position: absolute;
  z-index: 100;
  background-color: var(--gray100);
  right: -16px;
  width: auto;
  width: 140px;
  // max-width: 80vw;
  display: flex;
  border-width: 1px;
  border-style:  solid;
  border-color: var(--gray300);
  padding: 10px 14px;
  border-radius: 8px;
  // box-shadow: 0 1px 3px 1px var(--dropShadow);
  box-shadow: 0px 10px 15px -3px var(--dropShadow),0px 8px 6px -34px var(--dropShadow),0px 6px 3px -5px var(--dropShadow);
  flex-direction: column;
}

.AccessibilityButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.icon-Accessibility {
  height: 24px;
  width: 24px;
  fill: var(--oceanBlue200);
  @include TranslationAnimation;
  
  &:hover {
    fill: var(--oceanBlue300);
    @include TranslationAnimation;
  }
  &:active {
    fill: var(--oceanBlue400);
    @include TranslationAnimation;
  }
}

label {
  font-size: var(--textSize50);
  color: var(--gray800)
}
