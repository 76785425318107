
@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

@mixin GradientTextColor {
  background: -webkit-linear-gradient(45deg, var(--oceanBlue200), var(--burntOrange400));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// body {
//   font-size: 112.5%; /* 18px */
// }
html,
body,
.App,
input[type=range],
input[type=checkbox],
input[type=radio],
progress {
  accent-color: var(--accentColor);
}

input[type="checkbox"] {
  height: 1.5em;
  width: 1.5em;

  + label {
    margin-left: 4px;
  }
}

.App {
  text-align: start;
  margin:0;
  padding:0;
  font-family: 'Lato', sans-serif;
  background-color: var(--background);
  color: var(--gray800);
  font-size: var(--textSize100);
  line-height: 1.5;
  // overflow-x: clip;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }

  // height: 100vh;
  // position: relative;
}


.App-content > div:not(.menu) {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    max-width: 1200px;
    margin: 0;
  }

}

.App-content {
  padding: 0 32px;
  margin: 0 auto;
  width: calc(100% - 64px);
  max-width: 800px;
  display: flex;
  flex-direction: column;
}



.App-content {
  grid-area: content;
  // position: relative;
  padding-top: 16px;
  // min-height: calc(100vh - 16px);
  // display: grid;
  // grid-template-columns: 240px auto;

  @media screen and (min-width: 800px) {
    padding-top: 16px;
    padding-left: calc(var(--menuWidth) + 64px); // sidebar width is 304px, padding is 16px = 320px
    padding-right: 64px;
  }
}

.App-header-content--right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  // gap: 18px;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.25em;
  margin-top: 1.25em;
  line-height: 1.2;
  position: relative;
  // -webkit-font-smoothing: subpixel-antialiased;
}

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  font-family: var(--fontSerifStack);
  font-weight: 700;
  // -webkit-font-smoothing: subpixel-antialiased;
  color: var(--gray800);
  line-height: 1.2;
}

hgroup {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

hgroup:first-of-type {
  margin-top: 0;
}

h5, h6, .h5, .h6 {
  font-weight: 900;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 1.2;
}

h1, .h1 {
  font-size: var(--textSize800);
  z-index:1;
  max-width: max-content;
} 

.hero {
  margin-bottom: 1em;
  
  // margin-top: 0.5em;

  // @media screen and (min-width: 380px) {
  //   margin-top: 1em;
  // }
  // @media screen and (min-width: 600px) {
  //   margin-top: 1em;
  // }
  // @media screen and (min-width: 1000px) {
  //   margin-top: 2em;
  // }

  h1 {
    max-width: 18ch;
  }
  // .hero-overline {
  //   font-size: var(--textSize400);
  //   display: block;
  // }
  .hero-main {
    margin-top: 0.5em;
    line-height: 1.15;

    @media screen and (min-width: 800px) {
      margin-top: calc(1.75em - 12px);
    }
  }

  p.body4 {
    margin-bottom: 1em;
    // font-size: var(--textSize200);

    @media screen and (min-width: 380px) {
      // font-size: var(--textSize300);
      margin-bottom: 0.75em;
    }
  }
}

h2, .h2 {
  font-size: var(--textSize500);

}
h3, .h3 {
  font-size: var(--textSize400);
}
h4, .h4 {
  font-size: var(--textSize300);
  margin-bottom: 0;
}
h5, .h5 {
  font-size: var(--textSize200);
}
h6, .h6 {
  font-size: var(--textSize100);
}

h6.overline, .h6.overline {
  color: var(--gray500);

  + h2 {
    margin-top: 0;
  }
}

hgroup {
  h1 + h2,
  h1 + h3,
  h1 + h4,
  h2 + h3,
  h2 + h4,
  h2 + h5,
  .h1 + .h2,
  .h1 + .h3,
  .h1 + .h4,
  .h2 + .h3,
  .h2 + .h4,
  .h2 + .h5 {
    margin-top: 0;
  }
  h5:first-of-type,
  .h5:first-of-type {
    margin-bottom: 0;
    margin-top: 1em;
  }
}

p {
  // -webkit-font-smoothing: subpixel-antialiased;
  max-width: 30em; // 42ch
  margin-block-start: 0;
  margin-block-end: 0.75em;
}

p + p {
  margin-block-start: 0.5em;
}

h5 + p,
h5 + ul,
.h5 + p,
.h5 + ul {
  margin-top: 0;
}

code {
  font-family: var(--fontMonoStack);
  font-size: var(--textSize100);
  display: inline;
  background-color: var(--gray200);
  border: 1px solid var(--gray300);
  color: var(--burntOrange400);
  border-radius: 4px;
  padding: 0 4px;
  margin: 0 2px;
}

.body1 {
  font-family: var(--fontStack);
  font-size: var(--textSize500);
}
.body2 {
  font-family: var(--fontStack);
  font-size: var(--textSize400);
}
.body3,
.body3 li {
  font-family: var(--fontStack);
  font-size: var(--textSize300);
}
.body4,
.body4 li {
  font-family: var(--fontStack);
  // font-family: var(--fontSerifStack);
  font-size: var(--textSize200);
}
.body5,
p {
  font-family: var(--fontStack);
  font-size: var(--textSize100);
}

.body6 {
  font-family: var(--fontStack);
  font-size: var(--textSize50);
}

hgroup {
  h1 {
    margin-top: 0;
    line-height: 1; // Normal text (black) line height for better balance
  //   line-height: 1.25; // Necessary line height for gradient text color
  }
  h5, .h5 {
    font-family: var(--fontSerifStack);
    color: var(--gray500);
    font-weight: 400;
  }
  &::after {
    content: '\2022 \2022 \2022';
    letter-spacing: 1em;
    font-family: var(--fontSerifStack);
    line-height: 1;
    display: block;
    margin: 2em 0;
    color: var(--gray400);
  }
  .subtitle {
    margin-top: 0;
  }
}


.logo,
.coverPage {
    display: flex;
    flex-direction: row;
    // border: 1px solid var(--gray300);
    justify-content: flex-start;
    align-items: flex-end;
    font-family: var(--fontSerifStack);
    color: var(--gray800) !important;
    text-decoration: none;
    text-align: left;
    font-optical-sizing: auto;
    padding: 0px 0px 12px; // 20
    width: auto;  //240px
    @media screen and (min-width: 800px) {
      width: 280px;
    }

    .logoTop {
      font-family: var(--fontSerifStack);
      margin: 0;
      font-weight: 500;
      font-size: 0.85em;
      line-height: 1;
      position: relative;
      @media screen and (min-width: 800px) {
        font-size: 0.875em;
      }
    }
    .logoBottom {
      font-family: var(--fontSerifStack);
      margin: 0;
      font-weight: 500;
      font-size: 0.875em;
      line-height: 1;
    }
    .logoLarge {
      position: relative;
      font-family: var(--fontSerifStack);
      font-weight: 600;
      // text-transform: uppercase;
      font-size: 1.2em;
      line-height: 1;
      margin-top: 0.15rem;
      margin-bottom: 0.1rem;

      @media screen and (min-width: 800px) {
        font-size: 2em;
      }
    }
    .dotCom {
      display: inline;
      font-weight: 600;
      margin-bottom: -2px;
      margin-left: 0.125rem;
      font-size: var(--textSize75);
      color: var(--gray500);

      @media screen and (min-width: 800px) {
        font-size: var(--textSize100);
        margin-bottom: -1px;
      }
    }
}

a.logo {
  position: relative;
  width: fit-content;
  @include TranslationAnimation;

  &:active::after {
    background-color: var(--oceanBlue200);
  }

  &::after {
    display: block;
    position: absolute;
    height: 0;
    width: 2px;
    top: 50%;
    background-color: var(--oceanBlue100);
    left: 0;
    content: ' ';
    @include TranslationAnimation;
  }

  &:hover::after  {
    height: 100%;
    top: 0;
    @include TranslationAnimation;
  }

  @media screen and (min-width: 800px) {
    padding-left: 10px;
  }
}

.coverPage {
  width: 100%;
  max-width: 800px;
  align-self: center;
  padding: 56px;
  
  .logoTop,
  .logoBottom {
    font-size: var(--textSize300);
    @media screen and (min-width: 1000px) {
      font-size: var(--textSize400);
    }
  }
  .logoLarge {
    font-size: var(--textSize800);
    margin-top: 0;
    margin-bottom: 0.4rem;
    @media screen and (min-width: 600px) {
      font-size: var(--textSize900);
    }
    @media screen and (min-width: 1000px) {
      font-size: var(--textSize1000);
    }
  }
  hr.separator {
    border: 1px solid var(--gray400);
    margin: 40px 0 38px;
    display: flex;
    width: 4em;
  }
  .coverPage-description {
    max-width: 40ch;
    font-size: var(--textSize200)
  }
}

// .logoTop::before {
//   content: ' ';
//   display: inline-flex;
//   left: -1.5rem;
//   bottom: 0.4rem;
//   width: 1.25rem;
//   position: absolute;
//   height: 1px;
//   background-color: var(--gray300);
// }
// .logoTop::after {
//   content: ' ';
//   display: inline-flex;
//   right: -1.5rem;
//   bottom: 0.4rem;
//   width: 1.25rem;
//   position: absolute;
//   height: 1px;
//   background-color: var(--gray300);
// }

// .logoLarge::before {
//   content: ' ';
//   display: inline-flex;
//   left: -2.25rem;
//   bottom: 0.8rem;
//   width: 1.5rem;
//   position: absolute;
//   height: 1px;
//   background-color: var(--gray300);
// }
// .logoLarge::after {
//   content: ' ';
//   display: inline-flex;
//   right: -2.25rem;
//   bottom: 0.8rem;
//   width: 1.5rem;
//   position: absolute;
//   height: 1px;
//   background-color: var(--gray300);
// }

.App-header {
  // background-color: var(--gray100);
  // box-shadow: 0 20px 20px 10px var(--gray100);
  display:flex;
  height: 110px;
  font-size: 1rem;
  color: var(--gray700);
  position: relative;
  width: 100vw;
  z-index:10;
  margin-top: 0;
  align-items: center;

  @media screen and (min-width: 800px) {
    // height: 140px;
    position: fixed;
    height: 100vh;
    z-index: 1;
    width: fit-content;
    align-items: flex-start;
  }
}

.App-header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 24px;

  @media screen and (min-width: 800px) {
    flex-direction: column-reverse;
    padding: 32px;
    height: fit-content;
    align-items: flex-start;
  }

}

.App-link {
  color: #61dafb;
}

.intro {
  height: 70vh;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro_Text {
  font-size: var(--textSize200);
  margin-bottom: 0;
}


.intro_Heading {
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: -0.035em;
  font-size: var(--textSize800);
}

.intro_Heading::after {
  display: none;
}

.body--callout {
  color: var(--gray600);
  max-width: 40ch;
  font-family: var(--fontSerifStack);
  font-size: var(--textSize200);

  + .button {
    margin-top: 2em;
  }
} 

.cta {
  background: linear-gradient(to right, var(--oceanBlue400), var(--oceanBlue100));
  padding: 56px;
  color: var(--gray50);
  font-weight: 700;
}

a {
  text-underline-offset: 2px;
  color: var(--oceanBlue400)
}
a:visited {
  color: var(--oceanBlue400);
}

.colorRamp {
  display: block;
  height: var(--swatchSize);
  width: 100%;
  border-radius: 8px;
  box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.1), inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  @media screen and (min-width: 600px) {
    margin-bottom: 16px;
  }
}

.colorSwatch {
  height: var(--swatchSize);
  width: calc(var(--swatchSize) * 1.5);
  border-radius: 8px;
  box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.1), inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
  display: inline-flex;
  // flex-direction: column;
  // justify-content:space-between;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;
  @media screen and (min-width: 600px) {
    margin-right: 16px;
  }  
  margin-bottom: 8px;
  @media screen and (min-width: 600px) {
    margin-bottom: 16px;
  }

  .swatchContrastValue {
    display: flex;
    flex-direction: row;
    -webkit-font-smoothing: subpixel-antialiased;

    span {
      display: inline-flex;
      padding: 2px 0 0 6px;
      font-size: 0.75rem;
      opacity: 0.65;
    }
    span + span {
      padding: 2px 0 0 0;
    }
  }

  .swatchColorValue {
    padding: 2px 0 0 6px;
    display: block;
    font-size: 0.75rem;
    -webkit-font-smoothing: subpixel-antialiased;

  }
}

// .colorName {
//   font-size: 0.75rem;
// }

span.colorContrast {
  opacity: 1;
  font-size: 0.75rem;
}

.colorGroup {
  margin-bottom: 32px;
}

fieldset {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
  }

  div + div {
    margin-top: 8px;
  }
}

.ArtDetails {
  font-size: var(--textSize50);
  color: var(--gray500);
  font-style: italic;
  margin-top: 0;
  margin-bottom: 0;
}

iframe {
  align-self: center;
}

.heroImage {
  object-fit: cover;
  height: 60vh;
  width: 100vw;
  overflow: auto;
}

.splitView {
  display: grid;
  grid-template-rows: auto;
  img.circle {
    justify-self: center;
  }

  @media screen and (min-width: 800px) {
    grid-template-columns: 50% 50%;    
    img.circle {
      justify-self: flex-start;
      margin-top: 2.5em;
    }
  }
}

.splitView--centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img.circle {
    max-height: 300px;
  }
}

blockquote {
  margin: 24px 0 24px;
  padding: 12px;
  border-left: 4px solid var(--oceanBlue100);
  font-size: var(--textSize200);
  color: var(--gray800);
  font-family: var(--fontSerifStack);
  font-weight: 500;
  max-width: 60ch;

  cite {
    display: block;
    color: var(--gray500);
    font-size: var(--textSize100);
    font-family: var(--fontStack);
    padding-top: 0.5rem;

    &::before {
      display: inline-block;
      content: '\2014';
      margin-right: 0.25rem;
    }
  }
}

h4 + ul {
  margin-top: 0.5rem;
}
ul, ol {
  padding-left: 1rem;

  li {
    font-size: var(--textSize100);
  }

  ::marker {
    color: var(--gray400)
  }
} 

ol li {
  padding-left: 0.5rem;
}

a:not(.item):not(.button):not(.logo) {
  text-decoration: none;
  position: relative;
  word-break: break-word;

  &::after {
    content: ' ';
    width: 0;
    position: absolute;
    bottom: -2px;
    left: 50%;
    height: 2px;
    background-color: var(--oceanBlue200);
    opacity: 0;

    @include TranslationAnimation;
  }

  &:hover::after,
  &:active::after,
  &:focus::after {
    width: 100%;
    left: 0;
    opacity: 1;
    @include TranslationAnimation;

  }
}

.tableHeading {
  font-weight: 800;
  font-size: var(--textSize50)
}

.homeColorWrap {
  padding: 32px;
  display: flex;
  justify-self: center;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.2);

  @media screen and (min-width: 450px) {
    padding: 48px;
  }
  @media screen and (min-width: 800px) {
    padding: 56px;
  }
}
@keyframes colorchange1 {
  0% {
    background: #1fadea;
  }
  25% {
    background: #7d1ae0;
  }
  50% {
    background: #ef6001;
  }
  75% {
    background: #7ecd1d;
  }
  100% {
    background: #1fadea;
  }
}
@keyframes colorchange2 {
  0% {
    background: #0094e7;
  }
  25% {
    background: #a318ec;
  }
  50% {
    background: #fb8600;
  }
  75% {
    background: #56ba39;
  }
  100% {
    background: #0094e7;
  }
}
@keyframes colorchange3 {
  0% {
    background: #0078e5;
  }
  25% {
    background: #c918ee;
  }
  50% {
    background: #fcae00;
  }
  75% {
    background: #2ea646;
  }
  100% {
    background: #0078e5;
  }
}
@keyframes colorchange4 {
  0% {
    background: #1057e5;
  }
  25% {
    background: #ed1de6;
  }
  50% {
    background: #f4d50b;
  }
  75% {
    background: #008f4c;
  }
  100% {
    background: #1057e5;
  }
}


#color_wrap-1 {
  animation: colorchange1 45s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#color_wrap-2 {
  animation: colorchange2 45s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#color_wrap-3 {
  animation: colorchange3 45s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#color_wrap-4 {
  animation: colorchange4 45s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

input[type=color],
.swatch {
  display: flex;
  appearance: none;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  height: 160px;
  width: 160px;
  opacity: 0;
}

/*********** Baseline, reset styles ***********/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  // width: 25rem;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: var(--gray300);
  border-radius: 0.25rem;
  height: 0.25rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  outline: none;
  border: none;
  margin-top: -6px; /* Centers thumb on the track */
  background-color: var(--gray500);
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline: 3px solid var(--accentColor);
  outline-offset: 0.125rem;
  background-color: var(--accentColor);
}

/*********** Firefox styles ***********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: var(--gray300);
  border-radius: 0.5rem;
  height: 0.25rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  background-color: var(--gray500);
  border: none; /*Removes extra border that FF applies*/
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
}

input[type="range"]:focus::-moz-range-thumb{
  outline: 3px solid var(--accentColor);
  outline-offset: 0.125rem;
}

.gradientRamp {
  height: 32px;
  width: 100% !important;
}

.sourceCitations {
  max-width: 100%;

  ol li {
    font-size: var(--textSize50);
  }
}