.ColorPicker {
  display: flex;
  gap: 16px;
  margin: auto;

  @media screen and (min-width: 450px) {
    gap: 32px;
  }
  @media screen and (min-width: 1000px) {
    margin: 0;
  }
  .ColorPicker_swatch {
    height: 88px;
    width: 100%; // 366px/2
    border-radius: 16px;
    background-color: #eaeaea;
    margin-bottom: 8px;
    overflow: hidden;

    @media screen and (min-width: 450px) {
      height: 160px;
      width: 160px;
      border-radius: 50%;
    }
  }
  .ColorPicker_swatch--small {
    width: 100px;
    height: 100px;
    input[type=color] {
      width: 100px;
      height: 100px;
    }
  }
  .ColorPicker_swatch--tiny {
    width: 56px;
    height: 56px;
    border-radius: 12px;
    input[type=color] {
      width: 56px;
      height: 56px;
    }
  }
  
  .ColorPicker_controls {
    width: 100%;
    @media screen and (min-width: 450px) {
      width: 180px;
    }
  }

  label {
    font-size: var(--textSize50);
    font-family: var(--fontStack);
    line-height: 1;
  }
  .ColorPicker_inputGroup {
    margin-top: 8px;
    max-height: 34px;
  }
}

.ColorPicker-label {
  margin-top: 8px;
}

.ColorPicker-label + .ColorPicker {
  margin-top: -8px;
}

input[type=range] {
  padding: 8px 0;
  margin: 0;
}

.subSection .ColorPicker,
.ColorHarmonyPicker .ColorPicker {
  flex-direction: column;
  width: 100%;
  max-width: 366px;

  @media screen and (min-width: 450px) {
    flex-direction: row;
  }
}
.subSection .ColorPicker.colorPicker--row {
  flex-direction: row;
}

.ColorHarmonyPicker {
  .ColorHarmonyPicker_swatches {
    margin-top: 16px;
  }
  .swatch {
    width: 64px;
    height: 64px;
    border-radius: 8px;
  }
  .swatch--left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .swatch--right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
 
  .ColorPicker_inputGroup:first-of-type {
    margin-top: 0;
  }

  .Swatches-row {
    display: grid;
    grid-template-columns: 150px 64px 64px 64px;
    margin-bottom: 16px;
  }
  .Swatches-row--analogous {
    grid-template-areas: 'label color1 original color2'
  }
}

.ColorPicker_stringValue {
  width: fit-content;
  margin-top: 24px;
  
  @media screen and (min-width: 450px) {
    margin-top: 0;
  }
}