#interpolationRamps {
  .gradientRamp-Wrapper {
    display: grid;
    grid-template-columns: 7ch auto;
    width: 100%;
  }

  .gradientRamp {
    height: 44px;
  }

  .gradientRamp-Wrapper:first-of-type .gradientRamp {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  .gradientRamp-Wrapper:last-of-type .gradientRamp {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
  .gradientRamp-Wrapper label {
    margin-top: 8px;
  }
}