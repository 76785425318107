.ImageSlider {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  .ImageSlider-container {
    position: relative;
    display: block;
    max-width: 366px;
    width: 100%;
    margin-top: 0;
  
    .imageSlider-image {
      position: absolute;
      width: auto;
      margin: 0;
      max-width: 100%;
    }
  }

  .ImageSlider-container.contained {
    overflow: hidden;
    
    .imageSlider-image {
      max-width: fit-content;
    }
  }

  .ImageSlider-control {
    max-width: 100%;
  }
  
  .ImageSlider-labels {
    display: flex;
    margin-top: 8px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 366px;
  }
  .ImageSlider-controls {
    width: 100%;
    max-width: 366px;
  }

  @media screen and (min-width: 1000px) {
    align-items: flex-start;
  }
}

