@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.App-footer {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.App-footer-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4em;
  margin-top: 1em;
}

.App-footer-copyright {
  display: inline-flex;
  font-size: var(--textSize25);
  color: var(--gray600);
  margin-top: 1em;
}

.socialLinks {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
}

.socialLink {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: transparent;
  @include TranslationAnimation;
}

.socialLink + .socialLink {
  margin-left: 2rem;
}

.socialIcon {
  height: 2rem;
  width: 2rem;
  display: block;
  fill: var(--oceanBlue200);
  @include TranslationAnimation;
}

.socialLink:hover,
.socialLink:active,
.socialLink:focus {
  background-color: var(--oceanBlue100);
  transform: scale(1.2);
  @include TranslationAnimation;
}

.socialLink:hover .socialIcon,
.socialLink:active .socialIcon,
.socialLink:focus .socialIcon {
  transform: scale(0.9);
  fill: var(--oceanBlue400);
}