@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

section {
  margin: 0 auto 4em;
  width: 100%;
  // max-width: 800px;
  display: flex;
  flex-direction: column;

  .anchorLink {
    opacity: 0;
    @include TranslationAnimation;
  }

  h3:first-child, h4:first-child, h5:first-child {
    margin-top: 0;
    margin-bottom: 0.25rem;

    &:hover .anchorLink,
    &:focus .anchorLink,
    &:active .anchorLink {
      opacity: 1;
      @include TranslationAnimation;
    }
  }

  h3 {
    margin-bottom: 1rem;
  }

  // p.body4:last-of-type {
  //   margin-block-end: 2em;
  // }
}

.primarySection {
  margin-top: 1rem;
}

section.centered {
  align-items: center;
  text-align: center;
}

.centered.fullPage {
  width: auto;
  max-width: calc(100% - 112px);
  padding: 0 56px;
  // height: calc(100vh - 116px); // Full screen height minus size of footer
  justify-content: center;
  margin: 120px 0;
}

.subSection {
  display: grid;
  grid-template-rows: auto auto;
  gap: 32px;

  @media screen and (min-width: 1000px) {
    grid-template-columns: auto 366px;
  }

  &.smallSplitView {
    @media screen and (min-width: 1000px) {
      grid-template-columns: auto 280px;
    }
  }

  div {
    width: auto;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
  }

  img {
    max-width: 366px;
    width: 100%;
    // max-width: 100%;
    // max-height: 238px;
    margin-top: 0;
    margin: auto;

    @media screen and (min-width: 1000px) {
      margin: 0;
    }
  }

  .caption {
    width: 100%;
  }

  p ~ ul {
    margin-top: -0.5rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

p + .subSection {
  margin-top: 2em;
}

section.elements,
span.elements {  
  p {
    position: relative;
    padding: 12px 24px 16px 48px;
    background: var(--oceanBlue100);
    border-radius: 20px;
    width: fit-content;

    &::after {
      content: "?";
      padding: 0;
      font-family: var(--fontSerifStack);
      font-size: 16px;
      padding-left: 1px; // Random hack to center the character better
      font-weight: 900;
      height: 20px;
      width: 20px;
      color: var(--gray100);
      background-color: var(--oceanBlue400);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 14px;
      left: 14px;
      z-index: 2;
    }
  }
  p:first-of-type {
    margin-top: 8px;
  }
}

.subSection-compact {
  margin-bottom: 0;

  p:last-of-type {
    margin-bottom: 0;
  }

  :first-of-type {
    margin-top: 0;
  }
}