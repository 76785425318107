@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.pagination-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;

  @media screen and (min-width: 800px) {
    margin-bottom: 4em;
  }
}

.pagination-wrapper.pagination-end {
  justify-content: flex-end;
}

.pagination-wrapper.pagination-start {
  justify-content: flex-start;
}


.pagination-item {
  display: flex;
  flex-direction: column;

  .pagination-heading {
    font-size: var(--textSize50);
    color: var(--gray500);
  }
  .pagination-link {
    font-size: var(--textSize200);
    font-family: var(--fontSerifStack);

    // &::after {
    //   content: ' ';
    //   margin-top: 2px;
    //   width: 8px;
    //   height: 11px;
    //   background-repeat: no-repeat;
    //   margin-left: 6px;
    //   transform: rotate(0deg);
    //   @include TranslationAnimation;
    // }

    // @media (prefers-color-scheme: light) {
    //   &::after {
    //     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.828' height='10.828' viewBox='0 0 6.828 10.828'%3E%3Cpath id='Path_594507' data-name='Path 594507' d='M-471.625,7970.089l4,4-4,4' transform='translate(473.039 -7968.675)' fill='none' stroke='%23959595' stroke-linecap='round' stroke-width='2'/%3E%3C/svg%3E%0A");    
    //   }
    //   &:hover::after,
    //   &:focus::after,
    //   &:active::after {
    //     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.828' height='10.828' viewBox='0 0 6.828 10.828'%3E%3Cpath id='Path_594507' data-name='Path 594507' d='M-471.625,7970.089l4,4-4,4' transform='translate(473.039 -7968.675)' fill='none' stroke='%23000' stroke-linecap='round' stroke-width='2'/%3E%3C/svg%3E%0A");    
    //   }
    // }
    // @media (prefers-color-scheme: dark) {
    //   &::after {
    //     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.828' height='10.828' viewBox='0 0 6.828 10.828'%3E%3Cpath id='Path_594507' data-name='Path 594507' d='M-471.625,7970.089l4,4-4,4' transform='translate(473.039 -7968.675)' fill='none' stroke='%23959595' stroke-linecap='round' stroke-width='2'/%3E%3C/svg%3E%0A");    
    //   }
    //   &:hover::after,
    //   &:focus::after,
    //   &:active::after {
    //     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.828' height='10.828' viewBox='0 0 6.828 10.828'%3E%3Cpath id='Path_594507' data-name='Path 594507' d='M-471.625,7970.089l4,4-4,4' transform='translate(473.039 -7968.675)' fill='none' stroke='%23FFF' stroke-linecap='round' stroke-width='2'/%3E%3C/svg%3E%0A");    
    //   }
    // }
  }
}