.CitationList {
  display: block;
  margin-bottom: 4em;
  margin-top: 0;
  font-size: var(--textSize50);
  color: var(--gray500);
  font-style: normal;

  // &::before {
  //   content: '\2022 \2022 \2022';
  //   letter-spacing: 1em;
  //   font-family: var(--fontSerifStack);
  //   line-height: 1;
  //   display: block;
  //   margin: 0 0 4em 0;
  //   color: var(--gray400);
  // }

  li {
    font-size: var(--textSize50);
    padding-right: 12px;
  }
}

cite.Citation {
  font-style: normal;
}

.CitationList.sourceCitations {
  margin-top: calc(0.75rem + 1px);
  margin-bottom: 3px;

  li + li {
    margin-top: 0.5rem;
  }

  cite.Citation {
    color: var(--gray800);
    font-size: var(--textSize100);
  }
}

.CitationList.CitationList--tools {
  padding-left: 0;

  li {
    list-style-type: none;
  }
}