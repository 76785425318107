.caption {
  font-size: 0.785rem;
  text-align: center;
  font-style: italic;
  color: var(--gray600);
  margin-bottom: 0;
  margin-top: 1em;
  width: 100%;
  align-self: flex-start;
  display: block;
  max-width: var(--maxImageWidth);
}