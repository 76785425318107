@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.SearchPlaceholder {
  font-style: italic;
}

.SearchList-item {
  display: flex;
  flex-direction: column;
  padding: 4px 8px 6px;
  justify-content: flex-start;

  &.item {
    position: relative;
    text-decoration: none;
    flex-shrink: 0;
    color: var(--oceanBlue500);
    display: flex;
    // width: 100%;
    font-weight: 400;
    // font-family: var(--fontSerifStack);
    // font-size: var(--textSize100);
    font-family: var(--fontStack);
    font-size: var(--textSize50);

    @include TranslationAnimation;
  }

  &.item:hover,
  &.item:focus,
  &.item:active,
  &.item.selected {
    color: var(--oceanBlue600);
    @include TranslationAnimation;
  }
  &.item:active,
  &.item.selected {
    background-color: var(--gray200);
  }

  &.item::after {
    display: block;
    position: absolute;
    height: 0;
    width: 2px;
    top: 50%;
    background-color: var(--oceanBlue200);
    left: 0;
    content: ' ';
    @include TranslationAnimation;
  }

  &.item.selected::after,
  &.item:hover::after  {
    height: 100%;
    top: 0;
    @include TranslationAnimation;
  }

  .SearchList-item--category {
    font-size: var(--textSize50);
    color: var(--gray600);
    font-family: var(--fontSerifStack);
    @include TranslationAnimation;
  }

  &:hover {
    background-color: var(--oceanBlue100);

    .SearchList-item--category {
      @include TranslationAnimation;
      color: var(--gray800);
    }
  }
}
