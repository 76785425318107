.BlockRow {
  width: 100%;
  height: 16px;
  position: relative;
}

.GrayBlock {
  height: 16px;
  width: 80px !important;
  position: absolute;
  z-index: 2;
  background-color: #919191;
}

.BlockRow--foreground {
  @media (prefers-color-scheme: light) {
    background-color: white;
  }
  @media (prefers-color-scheme: dark) {
    background-color: black;
  }

  .GrayBlock {
    left: var(--MunkerGrayWhitePosition);
  }
}
.BlockRow--background {
  @media (prefers-color-scheme: light) {
    background-color: black;
  }
  @media (prefers-color-scheme: dark) {
    background-color: white;
  }

  .GrayBlock {
    right: var(--MunkerGrayBlackPosition);
  }
}
.BlockRow--bezoldCustom {
  &::after {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
    background: linear-gradient(90deg, rgba(0,0,255,1) 0%, rgba(0,0,255,1) 50%, rgba(255,255,0,1) 50%, rgba(255,255,0,1) 100%);
    opacity: var(--BezoldOpacity);
  }
}

.BlockRow--bezoldBackground {
  margin-top: 8px;
  height: 8px;
  z-index: 2;

  &:last-of-type {
    margin-bottom: 8px;
  }
  background-color: var(--BezoldCustomColor);
}