button {
  appearance: none;
  border: none;
  outline: none;
  background: none;
}

.button,
.menuButton {
  position: relative;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--textSize100);
  // user-select: none;
  // -webkit-user-select: none;
  // -ms-user-select: none;
  cursor: pointer;
  background-color: var(--background);
  color: var(--gray800);
  border: 2px solid var(--gray800);
  font-family: var(--fontStack);
  align-self: flex-start;
  font-weight: 600;
  text-decoration: none;
  padding: 8px 24px 11px;
  min-height: 44px;
  border-radius: 22px;
  transition: background-color 130ms ease-out, color 130ms ease-out;
}

.button:hover,
.button:focus,
.button:visited:hover,
.button:visited:focus  {
  background-color: var(--gray800);
  color: var(--background);
}
.button:visited {
  color: var(--gray800);
}

.centered .button {
  align-self: center;
}

.menuButton {
  align-items: center;
  justify-content: center;
  align-self: auto;
  padding: 10px;
  border: none;
  width: 44px;
  height: 44px;

  svg {
    fill: var(--gray800);
  }

  &:hover,
  &:focus,
  &:visited:hover,
  &:visited:focus  {
    background-color: var(--gray200);
  }

  @media screen and (min-width: 800px) {
    display: none;
  }
}

.button--reset,
.button--buymecoffee {
  min-height: auto;
  margin-top: 8px;
  padding: 3px 12px 5px;
  font-size: var(--textSize50);
}

.button--buymecoffee,
.button--buymecoffee:visited {
  background-color: var(--oceanBlue300);
  border-color: var(--oceanBlue300);
  color: var(--background);
}
.button--buymecoffee:hover,
.button--buymecoffee:focus,
.button--buymecoffee:visited:hover,
.button--buymecoffee:visited:focus  {
  background-color: var(--oceanBlue400);
  border-color: var(--oceanBlue400);
  color: var(--background);
}
.button--buymecoffee:focus,
.button--buymecoffee:visited:focus {
  border-color: var(--background);
}
.button--buymecoffee:active,
.button--buymecoffee:visited:active {
  background-color: var(--oceanBlue500);
  border-color: var(--oceanBlue500);
  color: var(--background);
}