html {
  // --blueYellowChannelOpacity: 0;
  // --redGreenChannelOpacity: 0;

  --blueChannelGradientOpacity: 0;
  --yellowChannelGradientOpacity: 0;
  --redChannelGradientOpacity: 0;
  --greenChannelGradientOpacity: 0;
  --redGreenHandlePos: 0;
  --blueYellowHandlePos: 0;
  --opponentSwatchColor: rgb(0, 0, 0);
}

.OpponentSlider-wrapper {
  margin: 3em 0;
  --visualsHeight: 180px;
  --borderColor: var(--gray300);

  display: flex;
  flex-direction: row;

  .OppoentSlider-title {
    font-size: var(--textSize50);
    font-weight: bold;
  }

  .OpponentSlider-col-Wave {
    display: flex;
    flex-direction: column;
  }
  .OpponentSlider-col-receptors {
    display: flex;
    flex-direction: column;
  }
  .OpponentSlider-col-channels {
    display: flex;
    flex-direction: column;
  }
  .OpponentSlider-col-color {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  .OpponentSlider-waveform {
    height: var(--visualsHeight);
    width: 200px;
    direction: rtl;
    display: block;
    overflow:hidden;

    .waveformWrap {
      right: 0;
      display: flex;
      height: 33%;
      align-items: center;
      flex-direction: row;
      width:fit-content;
    }
    svg {
      display: inline;
      fill: var(--gray500)
    }
  }

  .OpponentSlider-cones,
  .OpponentSlider-channels {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .OpponentSlider-cones {
    height: var(--visualsHeight);
    justify-content: space-evenly;
  }

  .OpponentSlider-channels {
    justify-content: space-between;
    height: 116px;
    margin-top: 46px;
  }

  .Cone {
    position: relative;
    height: 32px;
    width: 80px;
    margin: 0 34px 0 0;
    border: 2px solid var(--borderColor);
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .OpponentSlider-cones::after {
    content: ' ';
    width: 2px;
    background-color: var(--borderColor);
    height: 56px;
    position: absolute;   
    right: 16px;
    top: 35px; 
  }

  .Cone::after {
    content: ' ';
    height: 2px;
    background-color: var(--borderColor);
    position: absolute;
  }
  .Cone:nth-of-type(1)::after,
  .Cone:nth-of-type(2)::after {
    width: 16px;
    right: -18px;
  }
  .Cone:nth-of-type(3)::after {
    width: 34px;
    right: -36px;
  }
  .Cone:nth-of-type(1) {
    background-color: rgba(255, 0, 0, var(--LConeAlpha));
  }
  .Cone:nth-of-type(2) {
    background-color: rgba(0, 255, 0, var(--MConeAlpha));    
  }
  .Cone:nth-of-type(3) {
    background-color: rgba(0, 0, 255, var(--SConeAlpha));
  }


  .ChannelSlider {
    height: 32px;
    width: 140px;
    border: 2px solid var(--borderColor);
    position: relative;
    border-radius: 8px;
    background: black;
    position:relative;
  }

  .ChannelSlider::before {
    content: ' ';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
  }
  .ChannelSlider:nth-of-type(2)::before {
    // opacity: var(--blueYellowChannelOpacity);
    background: linear-gradient(90deg, rgba(0, 0, 255, var(--blueChannelGradientOpacity)) 0%, rgba(0, 0, 255, 0) 100%), linear-gradient(90deg, rgba(255, 255, 0, 0), rgba(255, 255, 0, var(--yellowChannelGradientOpacity)));
    // background: linear-gradient(90deg, rgba(0,0,255,1) 0%, rgba(59,98,115,1) 25%, rgba(129,157,112,1) 50%, rgba(194,208,91,1) 75%, rgba(255,255,0,1) 100%);
  }

  .ChannelSlider:nth-of-type(1)::before {
    background: linear-gradient(90deg, rgba(0, 255, 0, var(--greenChannelGradientOpacity)) 0%, rgba(0, 255, 0, 0) 100%), linear-gradient(90deg, rgba(255, 0, 0, 0), rgba(255, 0, 0, var(--redChannelGradientOpacity)));
    // opacity: var(--redGreenChannelOpacity);
    // background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(236,120,0,1) 25%, rgba(205,173,0,1) 50%, rgba(156,216,0,1) 75%, rgba(0,255,0,1) 100%);
  }

  .ChannelSlider::after {
    content: ' ';
    background-color: var(--borderColor);
    position: absolute;
  }

  .ChannelSlider:nth-of-type(1)::after {
    width: 16px;
    height: 2px;
    left: -18px;
    top: calc(50% - 2px);
  }
  .ChannelSlider:nth-of-type(2)::after {
    width: 2px;
    height: 44px;
    top: -46px;
    left: calc(50% - 2px);
  }

  .ChannelSlider-handle {
    width: 20px;
    height: 20px;
    top: 4px;
    border-radius: 16px;
    border: 2px solid white;
    box-shadow: 0 0 0 2px rgba(0,0,0, 0.3), inset 0 0 0 2px rgba(0,0,0, 0.3);
    position: absolute;
  }
  .ChannelSlider-handle#redGreenHandle {
    left: var(--redGreenHandlePos);
  }
  .ChannelSlider-handle#blueYellowHandle {
    left: var(--blueYellowHandlePos);
  }
  #opponentColorSwatch {
    margin-top: 32px;
    opacity: 1;
    position: relative;
    width: 140px; 
    height: 140px;
    display: block;
    border-radius: 100%;
    background: var(--opponentSwatchColor);
  }
  #opponentColorSwatch::after {
    content: ' ';
    height: 2px;
    background-color: var(--borderColor);
    width: 88px;
    position: absolute;   
    left: -88px;
    top: 70px; 
  }
}