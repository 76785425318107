.SearchWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  // width: calc(100% - 64px);
  // margin: 24px;
  width: calc(100% - 21px);
  margin: 14px 10px 10px;
}

.SearchField {
  appearance: none;
  background-color: var(--gray100);
  border: 2px solid var(--gray400);
  padding: 6px 16px 8px;
  font-family: var(--fontStack);
  color: var(--gray900);
  font-size: var(--textSize50);
  border-radius: 50px;

  @media (prefers-color-scheme: dark) {
    background-color: #0b0b0b;
  }
  @media (prefers-color-scheme: light) {
    background-color: var(--gray100);
  }
}

.SearchPopover {
  position: relative;

  .Popover {
    right: auto;
    left: 0;
    width: calc(100% - 20px);
    max-height: calc(70vh);
    overflow: auto;
    padding: 8px;
  }
}