

@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.navigation-sub {
  // height: 48px;
  // position: fixed;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  height: calc(100vh - 162px);
  overflow-y: auto;

  @media screen and (min-width: 800px) {
    position: relative;
    height: calc(100vh - 178px); // 156
    margin: 12px;
    padding: 0 16px 0 0;
  }
}

.navigation-sub .item {
  position: relative;
  text-decoration: none;
  flex-shrink: 0;
  color: var(--oceanBlue500);
  height: 2.2rem;
  align-items: center;
  display: flex;
  // width: 100%;
  font-weight: 400;
  // font-family: var(--fontSerifStack);
  // font-size: var(--textSize100);
  font-family: var(--fontStack);
  font-size: var(--textSize50);

  padding-left: 24px;
  padding-right: 0;
  @include TranslationAnimation;
}


.navigation-sub .item:hover,
.navigation-sub .item:focus,
.navigation-sub .item:active,
.navigation-sub .item.selected {
  color: var(--oceanBlue600);
  @include TranslationAnimation;
}
.navigation-sub .item:active,
.navigation-sub .item.selected {
  background-color: var(--gray200);
}


.navigation-sub .item::after {
  display: block;
  position: absolute;
  height: 0;
  width: 2px;
  top: 50%;
  background-color: var(--oceanBlue100);
  left: 0;
  content: ' ';
  @include TranslationAnimation;
}

.navigation-sub .item.selected::after,
.navigation-sub .item:hover::after {
  height: 100%;
  top: 0;
  @include TranslationAnimation;
}

.navigation-sub .item.selected::after {
  background-color: var(--oceanBlue200);
}

.navigation-sub + .Popover-wrapper {
  margin-left: 18px;
}

.navigation-sub .item--topLevel {
  flex-shrink: 0;
  padding-left: 12px;
  height: 36px;
  align-items: center;
  font-family: var(--fontSerifStack);
  display: flex;
  font-weight: 700;
  cursor: pointer;
}

.navigation-sub a.item--topLevel {
  font-family: var(--fontSerifStack);
  font-size: var(--textSize100);
}

.navigation-sub a.item--topLevel#lastMenuItem {
  margin-bottom: 64px;
}

.menu-group {
  height: fit-content;
  @include TranslationAnimation;
}

.menu-group {
  // max-height: 0;
  // min-height: 2.25rem;
  // transition: max-height 0.3s ease-out;
  // overflow: hidden;
  // @include TranslationAnimation;

  .item--topLevel {
    &::after {
      content: ' ';
      margin-top: 2px;
      width: 8px;
      height: 11px;
      background-repeat: no-repeat;
      margin-left: 6px;
      transform: rotate(0deg);
      @include TranslationAnimation;
    }

    @media (prefers-color-scheme: light) {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.828' height='10.828' viewBox='0 0 6.828 10.828'%3E%3Cpath id='Path_594507' data-name='Path 594507' d='M-471.625,7970.089l4,4-4,4' transform='translate(473.039 -7968.675)' fill='none' stroke='%23959595' stroke-linecap='round' stroke-width='2'/%3E%3C/svg%3E%0A");    
      }
      &:hover::after,
      &:focus::after,
      &:active::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.828' height='10.828' viewBox='0 0 6.828 10.828'%3E%3Cpath id='Path_594507' data-name='Path 594507' d='M-471.625,7970.089l4,4-4,4' transform='translate(473.039 -7968.675)' fill='none' stroke='%23000' stroke-linecap='round' stroke-width='2'/%3E%3C/svg%3E%0A");    
      }
    }
    @media (prefers-color-scheme: dark) {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.828' height='10.828' viewBox='0 0 6.828 10.828'%3E%3Cpath id='Path_594507' data-name='Path 594507' d='M-471.625,7970.089l4,4-4,4' transform='translate(473.039 -7968.675)' fill='none' stroke='%23959595' stroke-linecap='round' stroke-width='2'/%3E%3C/svg%3E%0A");    
      }
      &:hover::after,
      &:focus::after,
      &:active::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.828' height='10.828' viewBox='0 0 6.828 10.828'%3E%3Cpath id='Path_594507' data-name='Path 594507' d='M-471.625,7970.089l4,4-4,4' transform='translate(473.039 -7968.675)' fill='none' stroke='%23FFF' stroke-linecap='round' stroke-width='2'/%3E%3C/svg%3E%0A");    
      }
    }
  }


  .item {
    opacity: 0;
    display: none;
    @include TranslationAnimation;
  }
}
.menu-group.is-open {
  // max-height: 80em;
  // transition: max-height 0.3s ease-out;
  // height: fit-content;
  // @include TranslationAnimation;

  .item--topLevel::after {
    width: 7px;
    height: 11px;
    transform: rotate(90deg);
    @include TranslationAnimation;
  }

  .item {
    display: flex;
    opacity: 1;
    @include TranslationAnimation;
  }
}

.navigation-sub span.item {
  color: var(--gray300)
}

.item.todo::before {
  content: ' ';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: red;
  top: 0.75rem;
  left: 0.25rem;
  position: absolute;
  z-index: 20;
}

.item.incomplete::before {
  content: ' ';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: orange;
  top: 0.75rem;
  left: 0.25rem;
  position: absolute;
  z-index: 20;
}