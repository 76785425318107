.subSection {
  .SimultaneousContrast_wrapper {
    position: relative;
    height: 170px;
    width: 50%;
    display: inline-flex;
  }
  .SimultaneousContrastBlock {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 45px;
  }

  .SimHuePosition--left {
    left: var(--SimHuePosition);
  }
  .SimHuePosition--right {
    right: var(--SimHuePosition);
  }

  .SimLightnessPosition--left {
    left: var(--SimLightnessPosition);
  }
  .SimLightnessPosition--right {
    right: var(--SimLightnessPosition);
  }

  .SimSaturationPosition--left {
    left: var(--SimSaturationPosition);
  }
  .SimSaturationPosition--right {
    right: var(--SimSaturationPosition);
  }
}