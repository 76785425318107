
:root {
  --fontSerif: 'Playfair Display';
  --fontSerifStack: var(--fontSerif), Palatino, Georgia, serif;
  --fontStack: 'Lato', Helvetica, sans-serif;
  --fontMonoStack: 'Ubuntu Mono', Menlo, Monaco, monospace;

  --swatchSize: 48px;
  --maxImageWidth: 580px;
  
  @media screen and (min-width: 600px) {
    --swatchSize: 64px;
  }

  --textSize100: 0.875rem;
  @media screen and (min-width: 600px) {
    --textSize100: 1rem;
  }
  // --typeScale: 1.125;
  // @media screen and (min-width: 600px) {
  //   --typeScale: 1.125;
  // }
  font-size: 112.5%;

  --textSize100: 1rem;
  --textSize25: 0.79rem;
  --textSize50: 0.889rem;
  --textSize200: 1.125rem;
  --textSize300: 1.266rem;
  --textSize400: 1.424rem;
  --textSize500: 1.602rem;
  --textSize600: 1.802rem;
  --textSize700: 2.072rem;
  --textSize800: 2.281rem;
  --textSize900: 2.566rem;
  --textSize1000: 2.887rem;
  @media screen and (min-width: 800px) {
    font-size: 100%;

    --textSize25: 0.694rem;
    --textSize50: 0.833rem;
    --textSize200: 1.2rem;
    --textSize300: 1.44rem;
    --textSize400: 1.728rem;
    --textSize500: 2.074rem;
    --textSize600: 2.488rem;
    --textSize700: 2.986rem;
    --textSize800: 3.583rem;
    --textSize900: 4.3rem;
    --textSize1000: 5.16rem;  
  }

  --menuWidth: 320px;
  @media screen and (min-width: 600px) {
    --menuWidth: 300px;
  }
}
/* Providing defaults... */
@media (prefers-color-scheme: dark) {
  html {
    --background: #181818;
    background-color: var(--background);
    --dropShadow: rgba(0, 0, 0, 0.25);
  }
  .App,
  .go2197912528 > div {
    --background: #171717;
    --gray100: #171717;
    --gray200: #2f2f2f;
    --gray300: #494949;
    --gray400: #666666;
    --gray500: #828282;
    --gray600: #9b9b9b;
    --gray700: #b9b9b9;
    --gray800: #d7d7d7;
    --oceanBlue100: #002656;
    --oceanBlue200: #016aaa;
    --oceanBlue300: #0789ca;
    --oceanBlue400: #1baae7;
    --oceanBlue500: #61cdfa;
    --oceanBlue600: #b4e8fe;
    --burntOrange100: #360078;
    --burntOrange200: #8832e6;
    --burntOrange300: #a45ff0;
    --burntOrange400: #bf86f6;
    --burntOrange500: #dbb0fd;
    --burntOrange600: #efd8ff;

    --accentColor: var(--oceanBlue300)
  }
}
@media (prefers-color-scheme: light) {
  html {
    --background: #ffffff;
    background-color: var(--background);
    --dropShadow: rgba(0, 0, 0, 0.15);
  }
  .App,
  .go2197912528 > div {
    --background: #ffffff;
    --gray100: #fefefe;
    --gray200: #ebebeb;
    --gray300: #c1c1c1;
    --gray400: #959595;
    --gray500: #757575;
    --gray600: #545454;
    --gray700: #373737;
    --gray800: #1c1c1c;
    --oceanBlue100: #cbeffe;
    --oceanBlue200: #119ede;
    --oceanBlue300: #047cbd;
    --oceanBlue400: #0161a0;
    --oceanBlue500: #014681;
    --oceanBlue600: #003065;
    --burntOrange100: #f5e4ff;
    --burntOrange200: #b579f4;
    --burntOrange300: #984eed;
    --burntOrange400: #7f21e2;
    --burntOrange500: #5d00bd;
    --burntOrange600: #41008e;

    --accentColor: var(--oceanBlue300)
  }
}

@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}
