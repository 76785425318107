.ContrastImageContainer {
  background: #909090;
}

.ContrastImageContainer--round {
  border-radius: 50%;
  overflow: hidden;
}

.ContrastWrapper {
  position: relative;
}

.ContrastBackground {
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  top: 0;
  left: 0;
  opacity: var(--ContrastSensitivityContrast);
  background: linear-gradient(90deg, #ffffff 0%, #fdfdfd 5%, #f3f3f3 10%, #e1e1e1 15%, #c1c1c1 20%, #9d9d9d 25%, #767676 30%, #545454 35%, #333333 40%, #191919 45%, #000000 50%, #191919 55%, #333333 60%, #545454 65%, #767676 70%, #9d9d9d 75%, #c1c1c1 80%, #e1e1e1 85%, #f3f3f3 90%, #fdfdfd 95%, #ffffff 100%);
  background-repeat: repeat;
  background-position: 50%;
  background-size: var(--ContrastSensitivityBackgroundSize);
}

.ContrastBlackOverlay {
  position: absolute;
  height: 100%;
  left:0;
  right: 0;
  z-index: 10;
  width: 100%;
  background-color: #000;
  opacity: var(--ContrastSensitivityBlackOverlay);
}